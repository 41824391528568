
import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 安全API接口
// --------------------------------------------

/**
 * 获取安全学习内容
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getLearnContent = async data => {
  return $.get({
    url: 'admin/study/studyList',
    data,
    isLoad: true,
  })
}

/**
 * 删除学习内容
 * @param {String} ids 删除内容的id
 */
export const delLearnContent = async ids => {
  return $.get({
    url: 'admin/study/studyDel',
    data: { ids },
  })
}

/**
 * 获取学习内容统计
 * @param {String} data 详看接口文档
 */
export const getLearnContentTotal = async data => {
  return $.get({
    url: 'admin/study/studyLog',
    data,
    isLoad: true,
  })
}

/**
 * 获取安全学习分类
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getLearnType = async data => {
  return $.get({
    url: 'admin/study/studyTypeList',
    data,
    isLoad: true,
  })
}

/**
 * 删除学习分类
 * @param {String} ids 删除的类型 id
 */
export const delLearnType = async ids => {
  return $.get({
    url: 'admin/study/studyTypeDel',
    data: { ids },
  })
}

/**
 * 修改学习分类
 * @param {Object} data 详看接口文档
 */
export const updLearnType = async data => {
  return $.post({
    url: 'admin/study/studyTypeEdit',
    data,
  })
}

/**
 * 添加学习分类
 * @param {Object} data 详看接口文档
 */
export const addLearnType = async data => {
  return $.post({
    url: 'admin/study/studyTypeAdd',
    data,
  })
}

/**
 * 获取学习分类详情
 * @param {String} id 学习分类id
 */
export const getLearnTypeDetail = async id => {
  return $.get({
    url: 'admin/study/studyTypeDetail',
    data: { id },
  })
}

/**
 * 获取学习稽查列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getLearnCheck = async data => {
  return $.get({
    url: 'admin/study/auditList',
    data,
    isLoad: true,
  })
}

/**
 * 删除学习稽查
 * @param {String} ids 稽查id
 */
export const delLearnCheck = async ids => {
  return $.get({
    url: 'admin/study/auditDel',
    data: { ids },
  })
}

/**
 * 获取学习内容
 */
export const getLCSelect = async () => {
  return $.get({
    url: 'admin/select/getStudyList',
  })
}

/**
 * 添加学习稽查
 * @param {Object} data 详看接口文档
 */
export const addLearnCheck = async data => {
  return $.post({
    url: 'admin/study/auditAdd',
    data,
  })
}

/**
 * 修改学习稽查
 * @param {Object} data 详看接口文档
 */
export const updLearnCheck = async data => {
  return $.post({
    url: 'admin/study/auditEdit',
    data,
  })
}

/**
 * 获取学习分类
 */
export const getLCTSelect = async () => {
  return $.get({
    url: 'admin/select/getStudyTypeList',
  })
}

/**
 * 添加学习内容
 * @param {Object} data 详看文档接口
 */
export const addLearnContent = async data => {
  return $.post({
    url: 'admin/study/studyAdd',
    data,
  })
}

/**
 * 修改学习内容
 * @param {Object} data 详看接口文档
 */
export const updLearnContent = async data => {
  return $.post({
    url: 'admin/study/studyEdit',
    data,
  })
}

/**
 * 获取详情
 * @param {String} id 学习内容id
 */
export const getLearnContentDetail = async id => {
  return $.get({
    url: 'admin/study/studyDetail',
    data: { id },
  })
}

/**
 * 获取稽查详情
 * @param {String} id 稽查id
 */
export const getLearnCheckDetail = async id => {
  return $.get({
    url: 'admin/study/auditDetail',
    data: { id },
  })
}

/**
 * 获取试卷管理列表
 * @param {Object} data 详看接口
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getExamTestPaper = async data => {
  return $.get({
    url: 'admin/exam/paperList',
    data,
    isLoad: true,
  })
}

/**
 * 试卷管理: 分类、下拉框
 */
export const getETPSelect = async () => {
  return $.get({
    url: 'admin/select/getPaperTypeList',
  })
}

/**
 * 保存试题
 * @param {Object} data 详看接口文档
 */
export const addETPaper = async data => {
  return await $.post({
    url: 'admin/exam/addQuestions',
    data,
  })
}

/**
 * 删除试卷管理
 * @param {String} ids 试卷id
 */
export const delExamTestPaper = async ids => {
  return $.get({
    url: 'admin/exam/paperDel',
    data: { ids },
  })
}

/**
 * 获取试卷管理统计
 * @param {Object} data 详看接口文档
 */
export const getExamTestPaperTotal = async data => {
  return $.get({
    url: 'admin/exam/examLog',
    data,
    isLoad: true,
  })
}

/**
 * 添加试卷管理
 * @param {Object} data 详看接口文档
 */
export const addExamTestPaper = async data => {
  return $.post({
    url: 'admin/exam/paperAdd',
    data,
  })
}

/**
 * 获取试卷管理详情
 * @param {String} id 试卷id
 */
export const getExamTestPaperDetail = async id => {
  return $.get({
    url: 'admin/exam/paperDetail',
    data: { id },
  })
}

/**
 * 修改试卷管理
 * @param {Object} data 详看接口文档
 */
export const updExamTestPaper = async data => {
  return $.post({
    url: 'admin/exam/paperEdit',
    data,
  })
}

/**
 * 获取试卷分类
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getExamTestPaperType = async data => {
  return $.get({
    url: 'admin/exam/paperTypeList',
    data,
    isLoad: true,
  })
}

/**
 * 删除试卷分类
 * @param {String} ids 试卷id
 */
export const delExamTestPaperType = async ids => {
  return $.get({
    url: 'admin/exam/paperTypeDel',
    data: { ids },
  })
}

/**
 * 添加试卷分类
 * @param {Object} data 详看接口文档
 */
export const addExamTestPaperType = async data => {
  return $.post({
    url: 'admin/exam/paperTypeAdd',
    data,
  })
}

/**
 * 修改试卷分类
 * @param {Object} data 详看接口文档
 */
export const updExamTestPaperType = async data => {
  return $.post({
    url: 'admin/exam/paperTypeEdit',
    data,
  })
}

/**
 * 获取试卷详情
 * @param {Object} id 分类id
 */
export const getExamTestPaperTypeDetail = async id => {
  return $.get({
    url: 'admin/exam/paperTypeDetail',
    data: { id },
  })
}

/**
 * 获取考题分类
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getExamTestQuesType = async data => {
  return $.get({
    url: 'admin/exam/queTypeList',
    data,
    isLoad: true,
  })
}

/**
 * 删除考题分类
 * @param {String} ids 考题id
 */
export const delExamTestQuesType = async ids => {
  return $.get({
    url: 'admin/exam/queTypeDel',
    data: { ids },
  })
}

/**
 * 修改考题分类
 * @param {Object} data 详看接口文档
 */
export const updExamTestQuesType = async data => {
  return $.post({
    url: 'admin/exam/queTypeEdit',
    data,
  })
}

/**
 * 添加考题分类
 * @param {Obejct} data 详看接口文档
 */
export const addExamTestQuesType = async data => {
  return $.post({
    url: 'admin/exam/queTypeAdd',
    data,
  })
}

/**
 * 获取考题详情
 */
export const getExamTestQuesTypeDetail = async id => {
  return $.get({
    url: 'admin/exam/queTypeDetail',
    data: { id },
  })
}

/**
 * 获取考题管理
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getExamTestQues = async data => {
  return await $.get({
    url: 'admin/exam/queList',
    data,
    isLoad: true,
  })
}

/**
 * 删除考题
 * @param {String} ids 考题id
 */
export const delExamTestQues = async ids => {
  return await $.get({
    url: 'admin/exam/queDel',
    data: { ids },
  })
}

/**
 * 试卷管理-添加试题: 类别、下拉框
 */
export const getETQSelect = async () => {
  return await $.get({
    url: 'admin/select/getQueTypeList',
  })
}

/**
 * 添加考题管理
 * @param {Object} data 详看接口文档
 */
export const addExamTestQues = async data => {
  return await $.post({
    url: 'admin/exam/queAdd',
    data,
  })
}

/**
 * 获取考题详情
 * @param {String} id 考题id
 */
export const getExamTestQuesDetail = async id => {
  return await $.get({
    url: 'admin/exam/queDetail',
    data: { id },
  })
}

/**
 * 修改考题
 * @param {Object} data 详看接口文档
 */
export const updExamTestQues = async data => {
  return await $.post({
    url: 'admin/exam/queEdit',
    data,
  })
}

/**
 * 下载考题模板
 */
export const temExamTestQues = async () => {
  return await $.get({
    url: 'admin/template/questTemp',
  })
}

/**
 * 导出数据
 */
export const expExamTestQues = async () => {
  return await $.get({
    url: 'admin/exam/queOut',
  })
}

/**
 * 导入数据
 * @param {File} file 文件对象
 */
export const impExamTestQues = async file => {
  return await $.upload({
    url: 'admin/exam/queImport',
    file,
  })
}
